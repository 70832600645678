import axios, { AxiosError } from "axios";
import { getUpdatedDateTime } from "./auth/login";

export const baseUrl = window.location.origin + "/api/1.0";
export const logoUrl = `${baseUrl}/logo`;
export function getLogoUrl(options: {
  orgId?: number,
  maxHeight?: number,
  maxWidth?: number
}) {
  return `${logoUrl}${(options.orgId) ? `?orgId=${options.orgId}`: ""}`
}
const axiosInstance = axios.create({
  baseURL: baseUrl,
});

axiosInstance.interceptors.response.use(
  (response) => {
    window.__RPSC_FRONTEND_LOGINMETA = {
      defaultTimeout: window.__RPSC_FRONTEND_LOGINMETA?.defaultTimeout,
      lastInteraction: new Date(),
      expectedTimeout: getUpdatedDateTime({milliseconds: (window.__RPSC_FRONTEND_LOGINMETA?.defaultTimeout) ? window.__RPSC_FRONTEND_LOGINMETA?.defaultTimeout : 9999999999}),
      username: window.__RPSC_FRONTEND_LOGINMETA?.username
    };
    return response;
  },
  (error: AxiosError) => {
    // Check error responses
    if(!error.response) return Promise.reject(error);
    if(error.response.status !== 401) return Promise.reject(error);

    // Check URLS
    const request: XMLHttpRequest = error.request;
    const loginUrl = baseUrl + "/auth/login";
    if(request.responseURL === loginUrl) return Promise.reject(error);
    const checkUrl = baseUrl + "/auth/check";
    if(request.responseURL !== checkUrl) return Promise.reject(error);

    // Have we ever logged in?
    if(window.__RPSC_WEBSOCKET === undefined) {
      // Reload if we don't have existing data in the login meta global
      window.location.reload();
    }

    // We have a login error, probably timed out or session has otherwise been destoryed
    window.__RPSC_FRONTEND_LOGINMETA = {
      defaultTimeout: window.__RPSC_FRONTEND_LOGINMETA?.defaultTimeout,
      lastInteraction: new Date(),
      expectedTimeout: new Date(),
      username: window.__RPSC_FRONTEND_LOGINMETA?.username
    };

    // throw error;
    return Promise.reject(error);
  }
);

export default axiosInstance;
